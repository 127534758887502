.playHeight {
	font-size: 10vw;
}

.logo {
	width: 100%;
	max-width: 50vw;
	height: 100%;
	padding-top: 320px;
}
