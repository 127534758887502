.shbar {
	border-top: 0px solid rgba(255, 255, 255, 0.2);
	max-width: 550px;
	margin: auto;
	padding-top: 10px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	> a {
		text-decoration: none;
		transition: color 0.3s;
		color: #fff;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px;
		margin: 3px;
		border-radius: 20px;

		&:hover .mdi {
			background: rgba(255, 255, 255, 0.1);
			box-shadow: 0px 0px 0px 90px rgba(255, 255, 255, 0.1);
		}
	}

	.mdi {
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.2);
		transition: box-shadow 0.3s, background 0.3s;
		display: block;
		font-size: 50px;
		line-height: 100px;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 50%;
		width: 100px;
		height: 100px;
	}

	.text {
		opacity: 0.7;
		transition: all 0.3s;
		top: 0px;
		position: relative;
		font-weight: 700;
		font-size: 24px;
		padding-top: 15px;
		display: block;
		text-align: center;
	}

	> a:hover .text {
		opacity: 1;
		top: -10px;
	}
}
